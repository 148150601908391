
  export default {
    name: `CallbackPage`,
    mounted() {
      !!this.$route?.query?.logout && this.logoutAction()
      !!this.$route?.query?.error && this.errorAction()
      this.redirectToHome()
    },
    methods: {
      logoutAction() {
        console.log(this.$store.state.autoLogout)
        !!this.$store.state.autoLogout ? this.infoMessage() : this.successMessage()
        this.$store.commit(`drawerMenu/closeDrawer`)
      },
      errorAction() {
        this.errorMessage()
      },
      redirectToHome() {
        this.$router.push({ path: `/` })
      },
      successMessage() {
        this.$store.dispatch(`flashMessages/messageSuccess`, {
          message: this.$t(`messages.logout_success`)
        })
      },
      infoMessage() {
        this.$store.dispatch(`flashMessages/messageInfo`, {
          message: this.$t(`messages.Logout_user_after_a_set_period_of_inactivity`),
          time: 0
        })
        this.$store.commit(`clearAutoLogout`)
      },
      errorMessage() {
        this.$store.dispatch(`flashMessages/messageError`, { message: this.$t(this.$route.query.error) })
      }
    }
  }
